<template>
<div>
    <vx-card class="mt-8">
        <div class="card-title">
            <h2>Update My Information</h2>
        </div>
        <div class="reseller-info">
            <div class="setting-block mb-8">
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <h3>General Information</h3>
                    </div>
                    <div class="vx-col lg:w-1/3 md:w-1/2 w-full mt-5">
                        <vs-input label="Company Name" v-model="companyName" name="companyName" class="w-full" v-validate="'required|min:1|max:300'" />
                        <span class="text-danger error-msg">{{errors.first("companyName")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/3 md:w-1/2 w-full mt-5">
                        <vs-input label="Legal Name" v-model="legalName" name="legalName" v-validate="'required|min:1|max:300'" class="w-full" />
                        <span class="text-danger error-msg">{{errors.first("legalName")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/3 md:w-1/2 w-full mt-5">
                        <vs-input label="Domain Name" v-model="domainName" name="domainName" v-validate="'required|min:1|max:300'" class="w-full" :disabled="isDomainNameDisable"/>
                        <span class="text-danger error-msg">{{errors.first("domainName")}}</span>
                        <p class="mt-1 small-msg">This is your private label address. Use this address for you and your customers to access this application's website.</p>
                    </div>
                    <div class="vx-col lg:w-1/3 md:w-1/2 w-full mt-5">
                        <vs-input label="Actual Domain Name" v-model="actualDomainName" name="actualDomainName" class="w-full" v-validate="'required|min:1|max:300'" />
                        <span class="text-danger error-msg">{{errors.first("actualDomainName")}}</span>
                        <p class="mt-1 small-msg">This is your domain name of your website. This will be used in email communications to your customers.</p>
                    </div>
                    <div class="vx-col lg:w-1/3 md:w-1/2 w-full mt-5">
                        <vs-input label="Main Phone" v-model="mainPhone" name="mainPhone" v-validate="'required'" class="w-full" />
                        <span class="text-danger error-msg">{{errors.first("mainPhone")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/3 md:w-1/2 w-full mt-5">
                        <vs-input label="Sales Phone" v-model="salesPhone" name="salesPhone" class="w-full" v-validate="'required'" />
                        <span class="text-danger error-msg">{{errors.first("salesPhone")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/3 md:w-1/2 w-full mt-5">
                        <vs-input label="Support Phone" v-model="supportPhone" name="supportPhone" class="w-full" v-validate="'required'" />
                        <span class="text-danger error-msg">{{errors.first("supportPhone")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/3 md:w-1/2 w-full mt-5">
                        <vs-input label="General Email" v-model="generalEmail" name="generalEmail" class="w-full" v-validate="'required|email'" />
                        <span class="text-danger error-msg">{{errors.first("generalEmail")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/3 md:w-1/2 w-full mt-5">
                        <vs-input label="Sales Email" v-model="salesEmail" name="salesEmail" class="w-full" v-validate="'required|email'" />
                        <span class="text-danger error-msg">{{errors.first("salesEmail")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/3 md:w-1/2 w-full mt-5">
                        <vs-input label="Support Email" v-model="supportEmail" name="supportEmail" class="w-full" v-validate="'required|email'" />
                        <span class="text-danger error-msg">{{errors.first("supportEmail")}}</span>
                    </div>
                    <div class="vx-col lg:w-1/3 md:w-1/2 w-full mt-5">
                        <vs-input label="Send As Email" v-model="sendAsEmail" name="sendAsEmail" class="w-full" v-validate="'required|email'" />
                        <span class="text-danger error-msg">{{errors.first("sendAsEmail")}}</span>
                    </div>
                </div>
            </div>
            <div class="setting-block mb-8">
                <div class="vx-row">
                    <div class="vx-col md:w-1/4 w-full">
                        <h3 class="mb-5">Branding</h3>
                        <div class="current-img">
                            <span class="mb-1 mr-0 w-full">Current Image</span>
                            <img :src="'data:image/png;base64, ' + currentImage" />
                        </div>
                        <div class="upload-img mt-8 flex flex-wrap w-full">
                            <div class="upload-input">
                                <feather-icon icon="UploadCloudIcon" class="primary-c"></feather-icon>
                                <span class="file-text">Upload Image</span>
                                <input type="file" ref="file" name="newImage" v-validate="'image|ext:png,jpg,gif'" @change="changeImage($event)" />
                                <span class="text-danger error-msg">{{errors.first("newImage")}}</span>
                                <span class="text-danger error-msg" v-if="imageCustomError">{{imageCustomError}}</span>
                            </div>
                            <!-- <div>
                                <vs-button color="primary" type="filled" class="small-btn mt-3 sm:mb-0 sm:w-auto w-full" button="button">Upload New Image</vs-button>
                            </div> -->
                        </div>
                        <div class="current-img upload-new-img mt-8 mr-4" v-if="fileName">
                            <img :src="'data:image/' + extension + ';base64,' + fileName" />
                        </div>
                    </div>
                    <div class="vx-col md:w-3/4 w-full">
                        <div class="select-theme">
                            <h3 class="mb-5 flex items-center justify-between">
                                Select Theme
                                <vs-button :title="LabelConstant.buttonTitleResetToDefault" @click.prevent="cancelChanges()" color="primary" type="filled" icon-pack="feather" icon="icon-refresh-cw" class="mr-0 float-right"></vs-button>
                            </h3>
                            <div class="theme-option">
                                <h3>Card Colors</h3>
                                <div class="inner-option">
                                    <div class="card-option">
                                        <h5>First Card</h5>
                                        <ul>
                                            <li
                                                v-for="color in HexthemeColors"
                                                :key="color" :style="{ backgroundColor: color }"
                                                :class="{'shadow-outline':color == DashboardThemeV2.FirstColor}"
                                                @click="updateFirstCardColor(color)"
                                            />
                                            <li
                                                :style="{ backgroundColor: firstCardCustomcolor }"
                                                :class="{'shadow-outline': firstCardCustomcolor == DashboardThemeV2.FirstColor}"
                                                @click="updateFirstCardColor(firstCardCustomcolor)"
                                            />
                                            <li>
                                                <div class="color-picker">
                                                    <input class v-model="firstCardCustomcolor" type="color" />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="card-option">
                                        <h5>Second Card</h5>
                                        <ul>
                                            <li
                                                v-for="color in HexthemeColors"
                                                :key="color" :style="{ backgroundColor: color }"
                                                :class="{'shadow-outline': color == DashboardThemeV2.SecondColor}"
                                                @click="updateSecondCardColor(color)"
                                            />
                                            <li
                                                :style="{ backgroundColor: secondCardCustomcolor }"
                                                :class="{'shadow-outline': secondCardCustomcolor == DashboardThemeV2.SecondColor}"
                                                 @click="updateSecondCardColor(secondCardCustomcolor)"
                                                />
                                            <li>
                                                <div class="color-picker">
                                                    <input class v-model="secondCardCustomcolor" type="color" />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="card-option">
                                        <h5>Third card</h5>
                                        <ul>
                                            <li
                                                v-for="color in HexthemeColors"
                                                :key="color" :style="{ backgroundColor: color }"
                                                :class="{'shadow-outline':color == DashboardThemeV2.ThirdColor}"
                                                @click="updateThirdCardColor(color)"
                                            />
                                            <li
                                                :style="{ backgroundColor: thirdCardCustomcolor }"
                                                :class="{'shadow-outline':thirdCardCustomcolor ==DashboardThemeV2.ThirdColor}"
                                                @click="updateThirdCardColor(thirdCardCustomcolor)"
                                            />
                                            <li>
                                                <div class="color-picker">
                                                    <input class v-model="thirdCardCustomcolor" type="color" />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="card-option">
                                        <h5>Fourth card</h5>
                                        <ul>
                                            <li
                                                v-for="color in HexthemeColors"
                                                :key="color"
                                                :style="{ backgroundColor: color }"
                                                :class="{'shadow-outline':color == DashboardThemeV2.FourthColor}"
                                                @click="updateFourthCardColor(color)"
                                            />
                                            <li
                                                :style="{ backgroundColor: fourthCardCustomcolor }"
                                                :class="{'shadow-outline':fourthCardCustomcolor ==DashboardThemeV2.FourthColor}"
                                                @click="updateFourthCardColor(fourthCardCustomcolor)"
                                            />
                                            <!--<li :style="{'background-color':fourthCardCustomcolor }"></li>-->
                                            <li>
                                                <div class="color-picker">
                                                    <input class v-model="fourthCardCustomcolor" type="color" />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <h3>Theme Color</h3>
                                <div class="inner-option">
                                    <div class="card-option theme-colors">
                                        <ul>
                                            <li
                                                v-for="color in HexthemeColors"
                                                :key="color"
                                                :style="{ backgroundColor: color }"
                                                :class="{'shadow-outline':color == DashboardThemeV2.ThemeColor}"
                                                @click="updatePrimaryColor(color)"
                                            />
                                            <li
                                                :style="{ backgroundColor: customPrimaryColor }"
                                                :class="{'shadow-outline':customPrimaryColor == DashboardThemeV2.ThemeColor}"
                                                @click="updatePrimaryColor(customPrimaryColor)"
                                            />
                                            <li>
                                                <div class="color-picker">
                                                    <input v-model="customPrimaryColor" type="color" />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <h3>Navbar Color</h3>
                                <div class="inner-option">
                                    <div class="card-option theme-colors">
                                        <ul>
                                            <li
                                                v-for="color in HexthemeColors"
                                                :key="color"
                                                :style="{ backgroundColor: color }"
                                                :class="{'shadow-outline'
                                                :color == DashboardThemeV2.NavbarColor}"
                                                @click.prevent="updateNavbarColor(color)"
                                            />
                                            <li
                                                :style="{ backgroundColor: navbarCustomcolor }"
                                                :class="{'shadow-outline':navbarCustomcolor == DashboardThemeV2.NavbarColor}"
                                                @click.prevent="updateNavbarColor(navbarCustomcolor)"
                                            />
                                            <li>
                                                <div class="color-picker">
                                                    <input class v-model="navbarCustomcolor" type="color" />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <h3>Action Button Color</h3>
                                <div class="inner-option">
                                    <div class="card-option theme-colors">
                                        <ul>
                                            <li
                                                v-for="color in HexthemeColors"
                                                :key="color"
                                                :style="{ backgroundColor: color }"
                                                :class="{'shadow-outline':color == DashboardThemeV2.ActionButtonColor}"
                                                @click.prevent="updateActionButtonColor(color)"
                                            />
                                            <li
                                                :style="{ backgroundColor: customActionButtonColor }"
                                                :class="{'shadow-outline':customActionButtonColor ==DashboardThemeV2.ActionButtonColor}"
                                                @click.prevent="updateActionButtonColor(customActionButtonColor)"
                                            />
                                            <li>
                                                <div class="color-picker">
                                                    <input v-model="customActionButtonColor" type="color" />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <h3>Chart Colors</h3>
                                <div class="inner-option">
                                    <div class="card-option">
                                        <h5>Chart Colors 1</h5>
                                        <ul>
                                            <li
                                                v-for="color in HexthemeColors"
                                                :key="color"
                                                :style="{ backgroundColor: color }"
                                                :class="{'shadow-outline':color == DashboardThemeV2.ChartColor1}"
                                                @click="updateChartColor('ChartColor1','UPDATE_CHART1_COLOR','chart1',color)"
                                            />
                                            <li
                                                :style="{ backgroundColor: ccc1 }"
                                                :class="{'shadow-outline':ccc1 == DashboardThemeV2.ChartColor1}"
                                                @click="updateChartColor('ChartColor1','UPDATE_CHART1_COLOR','chart1',ccc1)"
                                            />
                                            <li>
                                                <div class="color-picker">
                                                    <input class v-model="ccc1" type="color" />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="card-option">
                                        <h5>Chart Colors 2</h5>
                                        <ul>
                                            <li
                                                v-for="color in HexthemeColors"
                                                :key="color"
                                                :style="{ backgroundColor: color }"
                                                :class="{'shadow-outline':color == DashboardThemeV2.ChartColor2}"
                                                @click="updateChartColor('ChartColor2','UPDATE_CHART2_COLOR','chart2',color)"
                                            />
                                            <li
                                                :style="{ backgroundColor: ccc2 }"
                                                :class="{'shadow-outline':ccc2 == DashboardThemeV2.ChartColor2}"
                                                @click="updateChartColor('ChartColor2','UPDATE_CHART2_COLOR','chart2',ccc2)"
                                            />
                                            <li>
                                                <div class="color-picker">
                                                    <input class v-model="ccc2" type="color" />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="card-option">
                                        <h5>Chart Colors 3</h5>
                                        <ul>
                                            <li
                                                v-for="color in HexthemeColors"
                                                :key="color"
                                                :style="{ backgroundColor: color }"
                                                :class="{'shadow-outline':color == DashboardThemeV2.ChartColor3}"
                                                @click="updateChartColor('ChartColor3','UPDATE_CHART3_COLOR','chart3',color)"
                                            />
                                            <li
                                                :style="{ backgroundColor: ccc3 }"
                                                :class="{'shadow-outline':ccc3 == DashboardThemeV2.ChartColor3}"
                                                @click="updateChartColor('ChartColor3','UPDATE_CHART3_COLOR','chart3',ccc3)"
                                            />
                                            <li>
                                                <div class="color-picker">
                                                    <input class v-model="ccc3" type="color" />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="card-option">
                                        <h5>Chart Colors 4</h5>
                                        <ul>
                                            <li
                                                v-for="color in HexthemeColors"
                                                :key="color"
                                                :style="{ backgroundColor: color }"
                                                :class="{'shadow-outline':color == DashboardThemeV2.ChartColor4}"
                                                @click="updateChartColor('ChartColor4','UPDATE_CHART4_COLOR','chart4',color)"
                                            />
                                            <li
                                                :style="{ backgroundColor: ccc4 }"
                                                :class="{'shadow-outline':ccc4 == DashboardThemeV2.ChartColor4}"
                                                @click="updateChartColor('ChartColor4','UPDATE_CHART4_COLOR','chart4',ccc4)"
                                            />
                                            <li>
                                                <div class="color-picker">
                                                    <input class v-model="ccc4" type="color" />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="card-option">
                                        <h5>Chart Colors 5</h5>
                                        <ul>
                                            <li
                                                v-for="color in HexthemeColors"
                                                :key="color"
                                                :style="{ backgroundColor: color }"
                                                :class="{'shadow-outline':color == DashboardThemeV2.ChartColor5}"
                                                @click="updateChartColor('ChartColor5','UPDATE_CHART5_COLOR','chart5',color)"
                                            />
                                            <li
                                                :style="{ backgroundColor: ccc5 }"
                                                :class="{'shadow-outline':ccc5 == DashboardThemeV2.ChartColor5}"
                                                @click="updateChartColor('ChartColor5','UPDATE_CHART5_COLOR','chart5',ccc5)"
                                            />
                                            <li>
                                                <div class="color-picker">
                                                    <input class v-model="ccc5" type="color" />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="card-option">
                                        <h5>Chart Colors 6</h5>
                                        <ul>
                                            <li
                                                v-for="color in HexthemeColors"
                                                :key="color"
                                                :style="{ backgroundColor: color }"
                                                :class="{'shadow-outline':color == DashboardThemeV2.ChartColor6}"
                                                @click="updateChartColor('ChartColor6','UPDATE_CHART6_COLOR','chart7',color)"
                                            />
                                            <li
                                                :style="{ backgroundColor: ccc6 }"
                                                :class="{'shadow-outline':ccc6 == DashboardThemeV2.ChartColor6}"
                                                @click="updateChartColor('ChartColor6','UPDATE_CHART6_COLOR','chart7',ccc6)"
                                            />
                                            <li>
                                                <div class="color-picker">
                                                    <input class v-model="ccc6" type="color" />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="card-option">
                                        <h5>Chart Colors 7</h5>
                                        <ul>
                                            <li
                                                v-for="color in HexthemeColors"
                                                :key="color"
                                                :style="{ backgroundColor: color }"
                                                :class="{'shadow-outline':color == DashboardThemeV2.ChartColor7}"
                                                @click="updateChartColor('ChartColor7','UPDATE_CHART7_COLOR','chart7',color)"
                                            />
                                            <li
                                                :style="{ backgroundColor: ccc7 }"
                                                :class="{'shadow-outline':ccc7 == DashboardThemeV2.ChartColor7}"
                                                @click="updateChartColor('ChartColor7','UPDATE_CHART7_COLOR','chart7',ccc7)"
                                            />
                                            <li>
                                                <div class="color-picker">
                                                    <input class v-model="ccc7" type="color" />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="card-option">
                                        <h5>Chart Colors 8</h5>
                                        <ul>
                                            <li
                                                v-for="color in HexthemeColors"
                                                :key="color"
                                                :style="{ backgroundColor: color }"
                                                :class="{'shadow-outline':color == DashboardThemeV2.ChartColor8}"
                                                @click="updateChartColor('ChartColor8','UPDATE_CHART8_COLOR','chart8',color)"
                                            />
                                            <li
                                                :style="{ backgroundColor: ccc8 }"
                                                :class="{'shadow-outline':ccc8 == DashboardThemeV2.ChartColor8}"
                                                @click="updateChartColor('ChartColor8','UPDATE_CHART8_COLOR','chart8',ccc8)"
                                            />
                                            <li>
                                                <div class="color-picker">
                                                    <input class v-model="ccc8" type="color" />
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <!-- <h3>Theme Mode</h3>
                                <div class="inner-option">
                                    <vs-radio
                                    v-model="DashboardThemeV2.ThemeMode"
                                    vs-value="light"
                                    class="mr-4"
                                    vs-name="theme-mode-light"
                                    >Light</vs-radio>
                                    <vs-radio
                                    v-model="DashboardThemeV2.ThemeMode"
                                    vs-value="dark"
                                    class="mr-4"
                                    vs-name="theme-mode-dark"
                                    >Dark</vs-radio>
                                    <vs-radio
                                    v-model="DashboardThemeV2.ThemeMode"
                                    vs-value="semi-dark"
                                    vs-name="theme-mode-semi-dark"
                                    >Semi Dark</vs-radio>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="setting-block mb-8">
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <h3>WordPress Plugin</h3>
                    </div>
                    <div class="vx-col w-full mt-5">
                        <div class="wordpress-plugin">
                            <p>
                                Download your personalized WordPress Plugin. This plugin is completely customized to reflect your saved reseller settings. Your logo, your company name, links to your site.
                            </p>
                            <a href="#" class="text-secondary" @click="downloadPugin()">
                                <feather-icon icon="DownloadIcon"></feather-icon>Download Plugin
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="setting-block mb-8">
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <h3>Services Available to your Customers</h3>
                    </div>
                    <div class="vx-col w-full mt-5">
                        <p class="error-msg mt-0">
                            Select the services you would like to offer your customers. Changing the selected services will impact all customers without individual customer settings.
                        </p>
                        <ul class="customers-service mt-6">
                            <li class="mb-6">
                                <vs-checkbox v-model="Anonymous_Visitor_Id" :disabled="true">Anonymous Visitor Identification</vs-checkbox>
                            </li>
                            <li class="mb-6">
                                <vs-checkbox v-model="Client_Email">Client Email Tracking</vs-checkbox>
                            </li>
                            <li class="mb-6">
                                <vs-checkbox v-model="Zapier_Integration">Zapier Integration</vs-checkbox>
                            </li>
                            <li class="mb-6">
                                <vs-checkbox v-model="Call_Tracking">Call Tracking</vs-checkbox>
                            </li>
                            <!-- <li>
                                <vs-checkbox v-model="Reviews">Reviews</vs-checkbox>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
            <div class="flex w-full justify-end flex-wrap">
                <vs-button color="primary" type="filled" class="small-btn mb-2 sm:mb-0 sm:w-auto w-full" @click="updateSetting()">{{ LabelConstant.buttonLabelUpdateMyInformation }}</vs-button>
                <vs-button color="grey" type="border" class="sm:ml-4 ml-0 mb-0 small-btn sm:w-auto w-full grey-btn" @click="cancelChanges()">{{ LabelConstant.buttonLabelCancelMyChange }}</vs-button>
            </div>
        </div>
    </vx-card>
</div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import {
    FormWizard,
    TabContent
} from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import themeConfig from "@/../themeConfig.js";
import VueJwtDecode from 'vue-jwt-decode'
/* Filter */
import vSelect from "vue-select";
import {
    Validator
} from "vee-validate";
const dict = {
    custom: {
        companyName: {
            required: "Please enter a company name",
            min: "Reseller name must be more than 1 and less than 300 characters",
            max: "Reseller name must be more than 1 and less than 300 characters"
        },
        legalName: {
            required: "Please enter a legal name",
            min: "Reseller name must be more than 1 and less than 300 characters",
            max: "Reseller name must be more than 1 and less than 300 characters"
        },
        domainName: {
            required: "Please enter a domain name",
            min: "Domain name must be more than 1 and less than 300 characters",
            max: "Domain name must be more than 1 and less than 300 characters"
        },
        actualDomainName: {
            required: "Please enter a actual domain name",
            min: "An Actual Domain name must be more than 1 and less than 300 characters",
            max: "An Actual Domain name must be more than 1 and less than 300 characters"
        },
        mainPhone: {
            required: "Please enter a main phone number"
        },
        salesPhone: {
            required: "Please enter a sales phone number"
        },
        supportPhone: {
            required: "Please enter a support phone number"
        },
        generalEmail: {
            required: "Please enter a general email",
            email: "Please enter a valid email address"
        },
        salesEmail: {
            required: "Please enter a sales email",
            email: "Please enter a valid email address"
        },
        supportEmail: {
            required: "Please enter a support email",
            email: "Please enter a valid email address"
        },
        sendAsEmail: {
            required: "Please enter a send as email",
            email: "Please enter a valid email address"
        },
        newImage: {
            image: "Please upload image",
            ext: "Please select an image file (png, jpg, gif)"
        }
    }
};
Validator.localize("en", dict);
export default {
    components: {
        VxCard,
        vSelect,
        FormWizard,
        TabContent,
        themeConfig
    },
    data() {
        return {
            isDomainNameDisable: null,
            navbarCustomcolor: "#000000",
            customPrimaryColor: "#3DC9B3",
            firstCardCustomcolor: "#000000",
            thirdCardCustomcolor: "#000000",
            fourthCardCustomcolor: "#000000",
            secondCardCustomcolor: "#000000",
            //custom chart color
            ccc1: "#000000",
            ccc2: "#000000",
            ccc3: "#000000",
            ccc4: "#000000",
            ccc5: "#000000",
            ccc6: "#000000",
            ccc7: "#000000",
            ccc8: "#000000",
            customActionButtonColor: "#000000",

            DashboardThemeV2: {
                ActionButtonColor: null,
                FirstColor: null,
                FourthColor: null,
                NavbarColor: null,
                SecondColor: null,
                ThemeColor: null,
                ThemeMode: "light",
                ThirdColor: null,

                ChartColor1: null,
                ChartColor2: null,
                ChartColor3: null,
                ChartColor4: null,
                ChartColor5: null,
                ChartColor6: null,
                ChartColor7: null,
                ChartColor8: null
            },
            themeColors: [
                "rgb(253, 157, 76)",
                "rgb(40, 199, 111)",
                "rgb(234, 84, 85)",
                "rgb(255, 159, 67)",
                "rgb(30, 30, 30)"
            ],
            //HexthemeColors: ["#fd9d4c", "#28c76f", "#ea5455", "#ff9f43", "#1e1e1e"],
            HexthemeColors: ["#044d80", "#7ac546", "#ecb940", "#cdcdcd"],
            routerTransitionsList: [{
                    text: "Zoom Fade",
                    value: "zoom-fade"
                },
                {
                    text: "Slide Fade",
                    value: "slide-fade"
                },
                {
                    text: "Fade Bottom",
                    value: "fade-bottom"
                },
                {
                    text: "Fade",
                    value: "fade"
                },
                {
                    text: "Zoom Out",
                    value: "zoom-out"
                },
                {
                    text: "None",
                    value: "none"
                }
            ],
            companyName: null,
            legalName: null,
            domainName: null,
            actualDomainName: null,
            mainPhone: null,
            salesPhone: null,
            supportPhone: null,
            generalEmail: null,
            salesEmail: null,
            supportEmail: null,
            sendAsEmail: null,
            Client_Email: false,
            Anonymous_Visitor_Id: false,
            Zapier_Integration: false,
            Call_Tracking: false,
            //Reviews: false,
            currentImage: false,
            fileName: null,
            orgId: null,
            ext: null,
            uid: null,
            imageCustomError: null,
        };
    },
    watch: {
        "DashboardThemeV2.ThemeMode"(val) {
            this.$store.dispatch("updateTheme", val);
        }
    },
    created() {
        //  console.log("nav")
        this.getOrganizationDetails();
        this.uid = this.$route.params.id;
        this.decodeJWT();
    },
    methods: {
        decodeJWT() {
            let jwtDecode = VueJwtDecode.decode(
                this.$store.state.authTokenVV[this.uid].token
            );
            let initialRole = jwtDecode.initialRole;
            this.isDomainNameDisable = initialRole == 'Global Admin' ? false : true
        },
        // update primary theme color
        updatePrimaryColor(color) {
            this.DashboardThemeV2.ThemeColor = color;
            this.$store.commit("UPDATE_PRIMARY_COLOR", color);
            this.$vs.theme({
                primary: color
            });
            let VVtoken = localStorage.getItem("VVtoken");
            let token = JSON.parse(VVtoken);
            token[this.uid].themeDetail.tempThemeColor = color;
            localStorage.setItem("VVtoken", JSON.stringify(token));
        },
        // update frist card color
        updateFirstCardColor(color) {
            this.DashboardThemeV2.FirstColor = color;
            this.$store.commit("UPDATE_FRIST_COLOR", color);
            this.$vs.theme({
                first: color
            });
            let VVtoken = localStorage.getItem("VVtoken");
            let token = JSON.parse(VVtoken);
            token[this.uid].themeDetail.tempFirstColor = color;
            localStorage.setItem("VVtoken", JSON.stringify(token));
        },
        // update second card color
        updateSecondCardColor(color) {
            this.DashboardThemeV2.SecondColor = color;
            this.$store.commit("UPDATE_SECOND_COLOR", color);
            this.$vs.theme({
                second: color
            });
            let VVtoken = localStorage.getItem("VVtoken");
            let token = JSON.parse(VVtoken);
            token[this.uid].themeDetail.tempSecondColor = color;
            localStorage.setItem("VVtoken", JSON.stringify(token));
        },
        // update third card color
        updateThirdCardColor(color) {
            this.DashboardThemeV2.ThirdColor = color;
            this.$store.commit("UPDATE_THIRD_COLOR", color);
            this.$vs.theme({
                third: color
            });
            let VVtoken = localStorage.getItem("VVtoken");
            let token = JSON.parse(VVtoken);
            token[this.uid].themeDetail.tempThirdColor = color;
            localStorage.setItem("VVtoken", JSON.stringify(token));
        },
        // update fourth card color
        updateFourthCardColor(color) {
            this.DashboardThemeV2.FourthColor = color;
            this.$store.commit("UPDATE_FOURTH_COLOR", color);
            this.$vs.theme({
                fourth: color
            });
            let VVtoken = localStorage.getItem("VVtoken");
            let token = JSON.parse(VVtoken);
            token[this.uid].themeDetail.tempFourthColor = color;
            localStorage.setItem("VVtoken", JSON.stringify(token));
        },
        // update navbar color
        updateNavbarColor(color) {
            //  console.log("before",this.$store.state.navbarColor);
            this.DashboardThemeV2.NavbarColor = color;
            this.$store.dispatch("updateNavbarColor", color);
            let VVtoken = localStorage.getItem("VVtoken");
            let token = JSON.parse(VVtoken);
            token[this.uid].themeDetail.tempNavbarColor = color;
            localStorage.setItem("VVtoken", JSON.stringify(token));
            // console.log("after",this.$store.state.navbarColor);
        },
        //update action button color
        updateActionButtonColor(color) {
            this.DashboardThemeV2.ActionButtonColor = color;
            this.$store.commit("UPDATE_ACTION_BUTTON_COLOR", color);
            this.$vs.theme({
                actionbutton: color
            });
            let VVtoken = localStorage.getItem("VVtoken");
            let token = JSON.parse(VVtoken);
            token[this.uid].themeDetail.tempActionButtonColor = color;
            localStorage.setItem("VVtoken", JSON.stringify(token));
        },
        // update chart colors
        updateChartColor(field, mutations, themeName, color) {
            let VVtoken = localStorage.getItem("VVtoken");
            let token = JSON.parse(VVtoken);
            if (field == "ChartColor1") {
                this.DashboardThemeV2.ChartColor1 = color;
                token[this.uid].themeDetail.tempChartColor1 = color;
            } else if (field == "ChartColor2") {
                this.DashboardThemeV2.ChartColor2 = color;
                token[this.uid].themeDetail.tempChartColor2 = color;
            } else if (field == "ChartColor3") {
                this.DashboardThemeV2.ChartColor3 = color;
                token[this.uid].themeDetail.tempChartColor3 = color;
            } else if (field == "ChartColor4") {
                this.DashboardThemeV2.ChartColor4 = color;
                token[this.uid].themeDetail.tempChartColor4 = color;
            } else if (field == "ChartColor5") {
                this.DashboardThemeV2.ChartColor5 = color;
                token[this.uid].themeDetail.tempChartColor5 = color;
            } else if (field == "ChartColor6") {
                this.DashboardThemeV2.ChartColor6 = color;
                token[this.uid].themeDetail.tempChartColor6 = color;
            } else if (field == "ChartColor7") {
                this.DashboardThemeV2.ChartColor7 = color;
                token[this.uid].themeDetail.tempChartColor7 = color;
            } else if (field == "ChartColor8") {
                this.DashboardThemeV2.ChartColor8 = color;
                token[this.uid].themeDetail.tempChartColor8 = color;
            }
            localStorage.setItem("VVtoken", JSON.stringify(token));
            this.$store.commit(mutations, color);
            this.$vs.theme({
                themeName: color
            });
        },
        //download plugin
        async downloadPugin() {
            let jwtDecode = VueJwtDecode.decode(this.$store.state.authTokenVV[this.uid].token)
            let jwtCustomerID = jwtDecode.CustomerId
            let jwtOrgId = jwtDecode.OrgId
            let jwtUserID = jwtDecode.UserId
            await this.axios
                .get("ws/Organization/GetOrganizationWordPressPlugin?oid=" + jwtOrgId + "&cid=" + jwtCustomerID + "&uid=" + jwtUserID, {
                    responseType: "blob"
                })
                .then(response => {
                    var fileURL = window.URL.createObjectURL(
                        new Blob([response.data], {
                            type: "application/zip"
                        })
                    );
                    var fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.setAttribute("download", this.companyName + "_WP_Plugin.zip");
                    document.body.appendChild(fileLink);
                    fileLink.click();
                });
        },
        //get organization details
        async getOrganizationDetails() {
            this.$vs.loading();
            this.imageCustomError = null;
            await this.axios
                .get("/ws/Organization/GetOrganization")
                .then(response => {
                    let data = response.data;
                    this.orgId = data.Id;
                    this.companyName = data.Org_Name;
                    this.legalName = data.Org_Name_Display;
                    this.domainName = data.Domain_Name;
                    this.actualDomainName = data.Org_Actual_domain_Name;
                    this.mainPhone = data.Org_Phone_Main;
                    this.salesPhone = data.Org_Phone_Sales;
                    this.supportPhone = data.Org_Phone_Support;
                    this.generalEmail = data.Org_Email_General;
                    this.salesEmail = data.Org_Email_Sales;
                    this.supportEmail = data.Org_Email_Support;
                    this.sendAsEmail = data.Email_From_Address;
                    this.Client_Email = data.Client_Email;
                    this.Anonymous_Visitor_Id = data.Anonymous_Visitor_Id;
                    this.Zapier_Integration = data.Zapier_Integration;
                    this.Call_Tracking = data.Call_Tracking;
                   // this.Reviews = data.Reviews;
                    this.currentImage = data.Org_Image_Current;

                    this.$store.commit("UPDATE_LOGGED_IN_ACCOUNT_LOGO",data.Org_Image_Current);

                    this.fileName = null;
                    this.extension = null;
                    this.DashboardThemeV2.ThemeColor = this.RGBToHex(data.DashboardThemeV2.ThemeColor);
                    this.DashboardThemeV2.FourthColor = this.RGBToHex(data.DashboardThemeV2.FourthColor);
                    this.DashboardThemeV2.ThirdColor = this.RGBToHex(data.DashboardThemeV2.ThirdColor);
                    this.DashboardThemeV2.SecondColor = this.RGBToHex(data.DashboardThemeV2.SecondColor);
                    this.DashboardThemeV2.FirstColor = this.RGBToHex(data.DashboardThemeV2.FirstColor);

                    this.DashboardThemeV2.ThemeMode = data.DashboardThemeV2.ThemeMode;

                    this.DashboardThemeV2.NavbarColor = this.RGBToHex(data.DashboardThemeV2.NavbarColor);
                    this.DashboardThemeV2.ChartColor1 = this.RGBToHex(data.DashboardThemeV2.ChartColor1);
                    this.DashboardThemeV2.ChartColor2 = this.RGBToHex(data.DashboardThemeV2.ChartColor2);
                    this.DashboardThemeV2.ChartColor3 = this.RGBToHex(data.DashboardThemeV2.ChartColor3);
                    this.DashboardThemeV2.ChartColor4 = this.RGBToHex(data.DashboardThemeV2.ChartColor4);
                    this.DashboardThemeV2.ChartColor5 = this.RGBToHex(data.DashboardThemeV2.ChartColor5);
                    this.DashboardThemeV2.ChartColor6 = this.RGBToHex(data.DashboardThemeV2.ChartColor6);
                    this.DashboardThemeV2.ChartColor7 = this.RGBToHex(data.DashboardThemeV2.ChartColor7);
                    this.DashboardThemeV2.ChartColor8 = this.RGBToHex(data.DashboardThemeV2.ChartColor8);

                    this.DashboardThemeV2.ActionButtonColor = this.RGBToHex(data.DashboardThemeV2.ActionButtonColor);

                    let VVtoken = localStorage.getItem("VVtoken");
                    let token = JSON.parse(VVtoken);

                    //chart 1
                    if (this.DashboardThemeV2.ChartColor1 != token[this.uid].themeDetail.tempChartColor1) {
                        this.DashboardThemeV2.ChartColor1 =token[this.uid].themeDetail.tempChartColor1;
                        if (this.HexthemeColors.indexOf(token[this.uid].themeDetail.tempChartColor1) == -1) {
                            this.ccc1 = token[this.uid].themeDetail.tempChartColor1;
                        }
                    } else {
                        if (this.HexthemeColors.indexOf(this.RGBToHex(data.DashboardThemeV2.ChartColor1)) == -1) {
                            this.ccc1 = this.RGBToHex(data.DashboardThemeV2.ChartColor1);
                        }
                    }
                    //chart 2
                    if (this.DashboardThemeV2.ChartColor2 != token[this.uid].themeDetail.tempChartColor2) {
                        this.DashboardThemeV2.ChartColor2 =token[this.uid].themeDetail.tempChartColor2;
                        if (this.HexthemeColors.indexOf(token[this.uid].themeDetail.tempChartColor2) == -1) {
                            this.ccc2 = token[this.uid].themeDetail.tempChartColor2;
                        }
                    } else {
                        if (this.HexthemeColors.indexOf(this.RGBToHex(data.DashboardThemeV2.ChartColor2)) == -1) {
                            this.ccc2 = this.RGBToHex(data.DashboardThemeV2.ChartColor2);
                        }
                    }
                    //chart 3
                    if (this.DashboardThemeV2.ChartColor3 != token[this.uid].themeDetail.tempChartColor3) {
                        this.DashboardThemeV2.ChartColor3 =token[this.uid].themeDetail.tempChartColor3;
                        if (this.HexthemeColors.indexOf(token[this.uid].themeDetail.tempChartColor3) == -1) {
                            this.ccc3 = token[this.uid].themeDetail.tempChartColor3;
                        }
                    } else {
                        if (this.HexthemeColors.indexOf(this.RGBToHex(data.DashboardThemeV2.ChartColor3)) == -1) {
                            this.ccc3 = this.RGBToHex(data.DashboardThemeV2.ChartColor3);
                        }
                    }
                    //chart 4
                    if (this.DashboardThemeV2.ChartColor4 != token[this.uid].themeDetail.tempChartColor4) {
                        this.DashboardThemeV2.ChartColor4 =token[this.uid].themeDetail.tempChartColor4;
                        if (this.HexthemeColors.indexOf(token[this.uid].themeDetail.tempChartColor4) == -1) {
                            this.ccc4 = token[this.uid].themeDetail.tempChartColor4;
                        }
                    } else {
                        if (this.HexthemeColors.indexOf(this.RGBToHex(data.DashboardThemeV2.ChartColor4)) == -1) {
                            this.ccc4 = this.RGBToHex(data.DashboardThemeV2.ChartColor4);
                        }
                    }
                    //chart 5
                    if (this.DashboardThemeV2.ChartColor5 != token[this.uid].themeDetail.tempChartColor5) {
                        this.DashboardThemeV2.ChartColor5 =token[this.uid].themeDetail.tempChartColor5;
                        if (this.HexthemeColors.indexOf(token[this.uid].themeDetail.tempChartColor5) == -1) {
                            this.ccc5 = token[this.uid].themeDetail.tempChartColor5;
                        }
                    } else {
                        if (this.HexthemeColors.indexOf(this.RGBToHex(data.DashboardThemeV2.ChartColor5)) == -1) {
                            this.ccc5 = this.RGBToHex(data.DashboardThemeV2.ChartColor5);
                        }
                    }
                    //chart 6
                    if (this.DashboardThemeV2.ChartColor6 != token[this.uid].themeDetail.tempChartColor6) {
                        this.DashboardThemeV2.ChartColor6 =token[this.uid].themeDetail.tempChartColor6;
                        if (this.HexthemeColors.indexOf(token[this.uid].themeDetail.tempChartColor6) == -1) {
                            this.ccc6 = token[this.uid].themeDetail.tempChartColor6;
                        }
                    } else {
                        if (this.HexthemeColors.indexOf(this.RGBToHex(data.DashboardThemeV2.ChartColor6)) == -1) {
                            this.ccc6 = this.RGBToHex(data.DashboardThemeV2.ChartColor6);
                        }
                    }
                    //chart 7
                    if (this.DashboardThemeV2.ChartColor7 != token[this.uid].themeDetail.tempChartColor7) {
                        this.DashboardThemeV2.ChartColor7 =token[this.uid].themeDetail.tempChartColor7;
                        if (this.HexthemeColors.indexOf(token[this.uid].themeDetail.tempChartColor7) == -1) {
                            this.ccc7 = token[this.uid].themeDetail.tempChartColor7;
                        }
                    } else {
                        if (this.HexthemeColors.indexOf(this.RGBToHex(data.DashboardThemeV2.ChartColor7)) == -1) {
                            this.ccc7 = this.RGBToHex(data.DashboardThemeV2.ChartColor7);
                        }
                    }
                    //chart 8
                    if (this.DashboardThemeV2.ChartColor8 != token[this.uid].themeDetail.tempChartColor8) {
                        this.DashboardThemeV2.ChartColor8 =token[this.uid].themeDetail.tempChartColor8;
                        if (this.HexthemeColors.indexOf(token[this.uid].themeDetail.tempChartColor8) == -1) {
                            this.ccc8 = token[this.uid].themeDetail.tempChartColor8;
                        }
                    } else {
                        if (this.HexthemeColors.indexOf(this.RGBToHex(data.DashboardThemeV2.ChartColor8)) == -1) {
                            this.ccc8 = this.RGBToHex(data.DashboardThemeV2.ChartColor8);
                        }
                    }
                    // action color button  check
                    if (this.DashboardThemeV2.ActionButtonColor != token[this.uid].themeDetail.tempActionButtonColor) {
                        this.DashboardThemeV2.ActionButtonColor =token[this.uid].themeDetail.tempActionButtonColor;
                        if (this.HexthemeColors.indexOf(token[this.uid].themeDetail.tempActionButtonColor) == -1) {
                            this.customActionButtonColor =token[this.uid].themeDetail.tempActionButtonColor;
                        }
                    } else {
                        if (this.HexthemeColors.indexOf(this.RGBToHex(data.DashboardThemeV2.ActionButtonColor)) == -1) {
                            this.customActionButtonColor = this.RGBToHex(data.DashboardThemeV2.ActionButtonColor);
                        }
                    }
                    //check if the theme color is not same as the theme color then assign the current selected color
                    if (this.DashboardThemeV2.ThemeColor != token[this.uid].themeDetail.tempThemeColor) {
                        this.DashboardThemeV2.ThemeColor =token[this.uid].themeDetail.tempThemeColor;
                        if (this.HexthemeColors.indexOf(token[this.uid].themeDetail.tempThemeColor) == -1) {
                            this.customPrimaryColor =token[this.uid].themeDetail.tempThemeColor;
                        }
                    } else {
                        if (this.HexthemeColors.indexOf(this.RGBToHex(data.DashboardThemeV2.ThemeColor)) == -1) {
                            this.customPrimaryColor = this.RGBToHex(data.DashboardThemeV2.ThemeColor);
                        }
                    }
                    //check if the fourth  color is not same as the theme color then assign the current selected color
                    if (this.DashboardThemeV2.FourthColor != token[this.uid].themeDetail.tempFourthColor) {
                        this.DashboardThemeV2.FourthColor =token[this.uid].themeDetail.tempFourthColor;
                        if (this.HexthemeColors.indexOf(token[this.uid].themeDetail.tempFourthColor) == -1) {
                            this.fourthCardCustomcolor =token[this.uid].themeDetail.tempFourthColor;
                        }
                    } else {
                        if (this.HexthemeColors.indexOf(this.RGBToHex(data.DashboardThemeV2.FourthColor)) == -1) {
                            this.fourthCardCustomcolor = this.RGBToHex(data.DashboardThemeV2.FourthColor);
                        }
                    }
                    //check if the third  color is not same as the theme color then assign the current selected color
                    if (this.DashboardThemeV2.ThirdColor != token[this.uid].themeDetail.tempThirdColor) {
                        this.DashboardThemeV2.ThirdColor =token[this.uid].themeDetail.tempThirdColor;
                        if (this.HexthemeColors.indexOf(token[this.uid].themeDetail.tempThirdColor) == -1) {
                            this.thirdCardCustomcolor =token[this.uid].themeDetail.tempThirdColor;
                        }
                    } else {
                        if (this.HexthemeColors.indexOf(this.RGBToHex(data.DashboardThemeV2.ThirdColor)) == -1) {
                            this.thirdCardCustomcolor = this.RGBToHex(data.DashboardThemeV2.ThirdColor);
                        }
                    }
                    //check if the second  color is not same as the theme color then assign the current selected color
                    if (this.DashboardThemeV2.SecondColor != token[this.uid].themeDetail.tempSecondColor) {
                        this.DashboardThemeV2.SecondColor =token[this.uid].themeDetail.tempSecondColor;
                        if (this.HexthemeColors.indexOf(token[this.uid].themeDetail.tempSecondColor) == -1) {
                            this.secondCardCustomcolor =token[this.uid].themeDetail.tempSecondColor;
                        }
                    } else {
                        if (this.HexthemeColors.indexOf(this.RGBToHex(data.DashboardThemeV2.SecondColor)) == -1) {
                            this.secondCardCustomcolor = this.RGBToHex(data.DashboardThemeV2.SecondColor);
                        }
                    }
                    //check if the first color is not same as the theme color then assign the current selected color
                    if (this.DashboardThemeV2.FirstColor != token[this.uid].themeDetail.tempFirstColor) {
                        this.DashboardThemeV2.FirstColor =token[this.uid].themeDetail.tempFirstColor;
                        if (this.HexthemeColors.indexOf(token[this.uid].themeDetail.tempFirstColor) == -1) {
                            this.firstCardCustomcolor =token[this.uid].themeDetail.tempFirstColor;
                        }
                    } else {
                        if (this.HexthemeColors.indexOf(this.RGBToHex(data.DashboardThemeV2.FirstColor)) == -1) {
                            this.firstCardCustomcolor = this.RGBToHex(data.DashboardThemeV2.FirstColor);
                        }
                    }
                    if (this.DashboardThemeV2.NavbarColor != token[this.uid].themeDetail.tempNavbarColor) {
                        this.DashboardThemeV2.NavbarColor =token[this.uid].themeDetail.tempNavbarColor;
                        if (this.themeColors.indexOf(token[this.uid].themeDetail.tempNavbarColor) == -1) {
                            this.navbarCustomcolor =token[this.uid].themeDetail.tempNavbarColor;
                        }
                    } else {
                        if (this.themeColors.indexOf(this.RGBToHex(data.DashboardThemeV2.NavbarColor)) == -1) {
                            this.navbarCustomcolor = this.RGBToHex(data.DashboardThemeV2.NavbarColor);
                        }
                    }
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //when new image is selected
        changeImage(e) {
            this.imageCustomError = null;
            var reader = new FileReader();
            reader.readAsDataURL(e.target.files[0]);
            let b64;
            let ext;
            setTimeout(() => {
                if (reader.result) {
                    b64 = reader.result;
                    b64 = b64.split("base64,");
                    ext = b64[0];
                    b64 = b64[1];
                    ext = ext.split("/");
                    ext = ext[1].split(";");
                    ext = ext[0];
                    if (ext == "png" || ext == "gif" || ext == "jpg" || ext == "PNG" || ext == "GIF" || ext == "JPG") {
                        this.extension = ext;
                        this.fileName = b64;
                        this.$store.commit("UPDATE_LOGGED_IN_ACCOUNT_LOGO", this.fileName);
                    } else {
                        if (this.errors.items.length <= 0) {
                            this.imageCustomError = "Please select an image file (png, jpg, gif)";
                        }
                    }
                }
            }, 200);
        },
        //check validations
        async updateSetting() {
            this.$validator.validateAll().then(async result => {
                if (result) {
                    this.updateInfo();
                }
            });
        },
        //update information
        async updateInfo() {
            this.$vs.loading();

            this.DashboardThemeV2.ThemeColor = this.hexToRGB(this.DashboardThemeV2.ThemeColor);
            this.DashboardThemeV2.FourthColor = this.hexToRGB(this.DashboardThemeV2.FourthColor);
            this.DashboardThemeV2.ThirdColor = this.hexToRGB(this.DashboardThemeV2.ThirdColor);
            this.DashboardThemeV2.SecondColor = this.hexToRGB(this.DashboardThemeV2.SecondColor);
            this.DashboardThemeV2.FirstColor = this.hexToRGB(this.DashboardThemeV2.FirstColor);
            this.DashboardThemeV2.NavbarColor = this.hexToRGB(this.DashboardThemeV2.NavbarColor);
            this.DashboardThemeV2.ActionButtonColor = this.hexToRGB(this.DashboardThemeV2.ActionButtonColor);
            this.DashboardThemeV2.ChartColor1 = this.hexToRGB(this.DashboardThemeV2.ChartColor1);
            this.DashboardThemeV2.ChartColor2 = this.hexToRGB(this.DashboardThemeV2.ChartColor2);
            this.DashboardThemeV2.ChartColor3 = this.hexToRGB(this.DashboardThemeV2.ChartColor3);
            this.DashboardThemeV2.ChartColor4 = this.hexToRGB(this.DashboardThemeV2.ChartColor4);
            this.DashboardThemeV2.ChartColor5 = this.hexToRGB(this.DashboardThemeV2.ChartColor5);
            this.DashboardThemeV2.ChartColor6 = this.hexToRGB(this.DashboardThemeV2.ChartColor6);
            this.DashboardThemeV2.ChartColor7 = this.hexToRGB(this.DashboardThemeV2.ChartColor7);
            this.DashboardThemeV2.ChartColor8 = this.hexToRGB(this.DashboardThemeV2.ChartColor8);

            let input = {
                Id: this.orgId,
                Org_Name: this.companyName,
                Org_Name_Display: this.legalName,
                Email_From_Address: this.sendAsEmail,
                is_Default_Org: true,
                Date_Created: new Date(),
                Date_Updated: new Date(),
                IsDeleted: true,
                Domain_Name: this.domainName,
                IsActive: true,
                Org_Actual_domain_Name: this.actualDomainName,
                Org_Phone_Main: this.mainPhone,
                Org_Phone_Sales: this.salesPhone,
                Org_Phone_Support: this.supportPhone,
                Org_Email_General: this.generalEmail,
                Org_Email_Sales: this.salesEmail,
                Org_Email_Support: this.supportEmail,
                Org_Image_Current: this.fileName ? this.fileName : this.currentImage,
                Org_Image_File_Ext: this.extension ? this.extension : "",
                Org_Main_Temp_Image_File: "",
                Customer_Id: "",
                Current_Theme: "",
                Anonymous_Visitor_Id: this.Anonymous_Visitor_Id,
                Call_Tracking: this.Call_Tracking,
               // Reviews: this.Reviews,
                Zapier_Integration: this.Zapier_Integration,
                Client_Email: this.Client_Email,
                DashboardThemeV2: this.DashboardThemeV2
            };
            await this.axios
                .post("/ws/Organization/UpdateOrganization", input)
                .then(() => {
                    let uid = this.$route.params.id;
                    let VVtoken = localStorage.getItem("VVtoken");
                    let token = JSON.parse(VVtoken);
                    // token[uid].themeDetail = this.DashboardThemeV2;

                    token[uid].themeDetail.ActionButtonColor = this.DashboardThemeV2.ActionButtonColor;
                    token[uid].themeDetail.ChartColor1 = this.DashboardThemeV2.ChartColor1;
                    token[uid].themeDetail.ChartColor2 = this.DashboardThemeV2.ChartColor2;
                    token[uid].themeDetail.ChartColor3 = this.DashboardThemeV2.ChartColor3;
                    token[uid].themeDetail.ChartColor4 = this.DashboardThemeV2.ChartColor4;
                    token[uid].themeDetail.ChartColor5 = this.DashboardThemeV2.ChartColor5;
                    token[uid].themeDetail.ChartColor6 = this.DashboardThemeV2.ChartColor6;
                    token[uid].themeDetail.ChartColor7 = this.DashboardThemeV2.ChartColor7;
                    token[uid].themeDetail.ChartColor8 = this.DashboardThemeV2.ChartColor8;
                    token[uid].themeDetail.FirstColor = this.DashboardThemeV2.FirstColor;
                    token[uid].themeDetail.FourthColor = this.DashboardThemeV2.FourthColor;
                    token[uid].themeDetail.NavbarColor = this.DashboardThemeV2.NavbarColor;
                    token[uid].themeDetail.SecondColor = this.DashboardThemeV2.SecondColor;
                    token[uid].themeDetail.ThemeColor = this.DashboardThemeV2.ThemeColor;
                    token[uid].themeDetail.ThemeMode = this.DashboardThemeV2.ThemeMode;
                    token[uid].themeDetail.ThirdColor = this.DashboardThemeV2.ThirdColor;

                    token[uid].themeDetail.tempActionButtonColor = this.RGBToHex(this.DashboardThemeV2.ActionButtonColor);
                    token[uid].themeDetail.tempChartColor1 = this.RGBToHex(this.DashboardThemeV2.ChartColor1);
                    token[uid].themeDetail.tempChartColor2 = this.RGBToHex(this.DashboardThemeV2.ChartColor2);
                    token[uid].themeDetail.tempChartColor3 = this.RGBToHex(this.DashboardThemeV2.ChartColor3);
                    token[uid].themeDetail.tempChartColor4 = this.RGBToHex(this.DashboardThemeV2.ChartColor4);
                    token[uid].themeDetail.tempChartColor5 = this.RGBToHex(this.DashboardThemeV2.ChartColor5);
                    token[uid].themeDetail.tempChartColor6 = this.RGBToHex(this.DashboardThemeV2.ChartColor6);
                    token[uid].themeDetail.tempChartColor7 = this.RGBToHex(this.DashboardThemeV2.ChartColor7);
                    token[uid].themeDetail.tempChartColor8 = this.RGBToHex(this.DashboardThemeV2.ChartColor8);
                    token[uid].themeDetail.tempFirstColor = this.RGBToHex(this.DashboardThemeV2.FirstColor);
                    token[uid].themeDetail.tempFourthColor = this.RGBToHex(this.DashboardThemeV2.FourthColor);
                    token[uid].themeDetail.tempNavbarColor = this.RGBToHex(this.DashboardThemeV2.NavbarColor);
                    token[uid].themeDetail.tempSecondColor = this.RGBToHex(this.DashboardThemeV2.SecondColor);
                    token[uid].themeDetail.tempThemeColor = this.RGBToHex(this.DashboardThemeV2.ThemeColor);
                    token[uid].themeDetail.tempThirdColor = this.RGBToHex(this.DashboardThemeV2.ThirdColor);

                    localStorage.setItem("VVtoken", JSON.stringify(token));
                    this.getOrganizationDetails();
                    this.fileName = null;
                    this.extension = null;
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Success",
                        text: "Your information has been updated successfully.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //reset of cancel changes
        cancelChanges() {
            this.imageCustomError = null;
            let uid = this.$route.params.id;
            let VVtoken = localStorage.getItem("VVtoken");
            let token = JSON.parse(VVtoken);
            this.$store.dispatch("updateTheme", token[uid].themeDetail.ThemeMode);

            this.$vs.theme({primary: token[uid].themeDetail.ThemeColor});
            this.$vs.theme({first: token[uid].themeDetail.FirstColor});
            this.$vs.theme({second: token[uid].themeDetail.SecondColor});
            this.$vs.theme({third: token[uid].themeDetail.ThirdColor});
            this.$vs.theme({fourth: token[uid].themeDetail.FourthColor});
            this.$vs.theme({actionbutton: token[uid].themeDetail.ActionButtonColor});

            this.navbarCustomcolor = "#000000";
            this.customPrimaryColor = "#3DC9B3";
            this.firstCardCustomcolor = "#000000";
            this.thirdCardCustomcolor = "#000000";
            this.fourthCardCustomcolor = "#000000";
            this.secondCardCustomcolor = "#000000";
            //custom chart color
            this.ccc1 = "#000000";
            this.ccc2 = "#000000";
            this.ccc3 = "#000000";
            this.ccc4 = "#000000";
            this.ccc5 = "#000000";
            this.ccc6 = "#000000";
            this.ccc7 = "#000000";
            this.ccc8 = "#000000";
            this.customActionButtonColor = "#000000";

            this.$store.commit("UPDATE_PRIMARY_COLOR",this.RGBToHex(token[uid].themeDetail.ThemeColor));
            this.$store.commit("UPDATE_FRIST_COLOR",this.RGBToHex(token[uid].themeDetail.FirstColor));
            this.$store.commit("UPDATE_SECOND_COLOR",this.RGBToHex(token[uid].themeDetail.SecondColor));
            this.$store.commit("UPDATE_THIRD_COLOR",this.RGBToHex(token[uid].themeDetail.ThirdColor));
            this.$store.commit("UPDATE_FOURTH_COLOR",this.RGBToHex(token[uid].themeDetail.FourthColor));
            this.$store.dispatch("updateNavbarColor",this.RGBToHex(token[uid].themeDetail.NavbarColor));
            this.$store.dispatch("updateChartColor8",this.RGBToHex(token[uid].themeDetail.ChartColor8));
            this.$store.dispatch("updateChartColor7",this.RGBToHex(token[uid].themeDetail.ChartColor7));
            this.$store.dispatch("updateChartColor6",this.RGBToHex(token[uid].themeDetail.ChartColor6));
            this.$store.dispatch("updateChartColor5",this.RGBToHex(token[uid].themeDetail.ChartColor5));
            this.$store.dispatch("updateChartColor4",this.RGBToHex(token[uid].themeDetail.ChartColor4));
            this.$store.dispatch("updateChartColor3",this.RGBToHex(token[uid].themeDetail.ChartColor3));
            this.$store.dispatch("updateChartColor2",this.RGBToHex(token[uid].themeDetail.ChartColor2));
            this.$store.dispatch("updateChartColor1",this.RGBToHex(token[uid].themeDetail.ChartColor1));
            this.$store.dispatch("updateactionButtonColor",this.RGBToHex(token[uid].themeDetail.ActionButtonColor));

            let themeDetail = token[uid].themeDetail;

            themeDetail.tempActionButtonColor = this.RGBToHex(token[uid].themeDetail.ActionButtonColor);
            themeDetail.tempChartColor1 = this.RGBToHex(token[uid].themeDetail.ChartColor1);
            themeDetail.tempChartColor2 = this.RGBToHex(token[uid].themeDetail.ChartColor2);
            themeDetail.tempChartColor3 = this.RGBToHex(token[uid].themeDetail.ChartColor3);
            themeDetail.tempChartColor4 = this.RGBToHex(token[uid].themeDetail.ChartColor4);
            themeDetail.tempChartColor5 = this.RGBToHex(token[uid].themeDetail.ChartColor5);
            themeDetail.tempChartColor6 = this.RGBToHex(token[uid].themeDetail.ChartColor6);
            themeDetail.tempChartColor7 = this.RGBToHex(token[uid].themeDetail.ChartColor7);
            themeDetail.tempChartColor8 = this.RGBToHex(token[uid].themeDetail.ChartColor8);
            themeDetail.tempFirstColor = this.RGBToHex(token[uid].themeDetail.FirstColor);
            themeDetail.tempSecondColor = this.RGBToHex(token[uid].themeDetail.SecondColor);
            themeDetail.tempThirdColor = this.RGBToHex(token[uid].themeDetail.ThirdColor);
            themeDetail.tempFourthColor = this.RGBToHex(token[uid].themeDetail.FourthColor);
            themeDetail.tempNavbarColor = this.RGBToHex(token[uid].themeDetail.NavbarColor);
            themeDetail.tempThemeColor = this.RGBToHex(token[uid].themeDetail.ThemeColor);

            token[uid].themeDetail = themeDetail;
            localStorage.setItem("VVtoken", JSON.stringify(token));
            this.getOrganizationDetails();
            this.$vs.notify({
                title: "Changes Reverted",
                text: "Your information has been restored to the original state.",
                color: "warning",
                iconPack: "feather",
                icon: "icon-alert-triangle",
                position: "top-right",
                time: 4000
            });
        }
    }
};
</script>
